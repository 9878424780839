import { Grid } from "@mui/material";
import { FC, ReactNode } from "react";

export interface GridItemContainerProps {
  spacing?: number;
  rowSpacing?: number;
  columnSpacing?: number;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  children?: ReactNode;
  className?: string;
}

export const GridItemContainer: FC<GridItemContainerProps> = ({
  spacing = 0,
  rowSpacing = 0,
  columnSpacing = 0,
  xs,
  sm,
  md,
  lg,
  xl,
  children,
  className,
}) => {
  return (
    <Grid
      className={className}
      container
      item
      spacing={spacing}
      rowSpacing={rowSpacing}
      columnSpacing={columnSpacing}
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
    >
      {children}
    </Grid>
  );
};
