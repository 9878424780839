export enum ApiErrorCode {
  OtherError = 1,
  NoSuchObjExist,
  NoSuchObjWithThatIdExist,
  InvalidLang,
  UserAlreadyExist,
  UserCreatingFail,
  UserWithIdDoesNotExist,
  UserWithLoginDoesNotExist,
  WrongCredentials,
  WrongFileFormat,
  EmailDoesNotExistInDatabase,
  ErrorDuringSendMail,
  ErrorDuringRemoveFile,
  UrlAlreadyExist,
}
