import { GridItem, GridItemContainer } from 'components/Layout';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled(GridItemContainer)(
  ({ theme }) => `
  gap: ${theme.spacing(2)};
`
);

export const IconContainer = styled('div')(
  ({ theme }) => `
  color: ${theme.palette.text.secondary}
  display: flex;
  align-items: center;
  justify-content: center;
`
);

export const TextContainer = styled(GridItem)(
  ({ theme }) => `
  flex: 1;
  justify-content: flex-start;
`
);

export const Text = styled(Typography)(
  ({ theme }) => `
  color: ${theme.palette.text.primary};
  `
);
