import { TextWithIcon } from 'components/Common/TextWithIcon';
import { CalendarIcon, ClockIcon, RingsIcon } from 'components/Icons';
import { GridContainer, GridItem } from 'components/Layout';
import { FC } from 'react';

interface WeddingInfoProps {}

export const WeddingInfo: FC<WeddingInfoProps> = () => {
  return (
    <GridContainer rowSpacing={6}>
      <GridItem xs={6}>
        <TextWithIcon
          variant='body1'
          leftIcon={<CalendarIcon color='secondary' />}
        >
          08.08.24
        </TextWithIcon>
      </GridItem>
      <GridItem xs={6}>
        <TextWithIcon
          variant='body1'
          leftIcon={<ClockIcon color='secondary' />}
        >
          13:00
        </TextWithIcon>
      </GridItem>
      <GridItem xs={12}>
        <TextWithIcon
          variant='body1'
          leftIcon={<RingsIcon color='secondary' />}
        >
          Parafia Rzymskokatolicka Podwyższenia Krzyża Świętego i Matki Bożej
          Częstochowskiej w Pszczynie
        </TextWithIcon>
      </GridItem>
    </GridContainer>
  );
};
