import { Heading, Photo, Space, SubHeader } from 'components/Common';
import { DemoMainLayout } from 'components/Layout/DemoMainLayout';
import { FC, useState, useEffect } from 'react';
import { Plan } from './Plan';
import FlowersUniversal from 'components/Images/universal_1.png';
import { Menu } from './Menu';
import { Organization } from './Organization';
import { useDemoTheme } from 'hooks/useDemoThemeContext';
import { FlowerAccessor } from 'config/Theme';
import { useWindowSize } from 'hooks/useScreenSize';
import { CenteredText } from './styled';

export const Party: FC = () => {
  const [space, setSpace] = useState(650);
  const { width } = useWindowSize();

  useEffect(() => {
    if (width) {
      if (width > 430) {
        setSpace(650);
        return;
      }
      if (width > 370) {
        setSpace(600);
        return;
      }
      if (width > 310) {
        setSpace(490);
        return;
      }
      if (width > 260) {
        setSpace(380);
        return;
      }
    }
  }, [width]);
  return (
    <DemoMainLayout>
      <Space space={space} />
      {/* <Heading variant='h4' text='headers.weddingPlan' />
      <Plan />
      <Space space={10} />
      <Heading variant='h4' text='headers.menu' />
      <Space space={10} />
      <Menu />
      <Space space={10} /> */}
      <Heading variant='h4' text='headers.organizationalMatters' />
      <Space space={10} />
      <Organization />
      <Space space={10} />
      <CenteredText variant='body1'>
        W razie jakichkolwiek wątpliwości i pytań służymy pomocą 🙂
      </CenteredText>
    </DemoMainLayout>
  );
};
