import { FC } from 'react';

import { GoogleMap, MarkerF, useLoadScript } from '@react-google-maps/api';

type GeolocationData = { lat: number; lng: number };

export interface MapProps {
  className?: string;
  zoom?: number;
  marker?: GeolocationData;
  center?: GeolocationData;
}

export const Map: FC<MapProps> = ({ className, zoom, center, marker }) => {
  const { isLoaded } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCp3DuYnedbO9Uk5cgwTVRaZ3YVMlhoOm0',
  });

  if (!isLoaded) return null;

  const onClick = () => {
    window.open('https://maps.google.com?q=' + marker?.lat + ',' + marker?.lng);
  };

  return (
    <GoogleMap
      zoom={zoom}
      center={center}
      mapContainerClassName={className}
      onClick={onClick}
    >
      {marker && <MarkerF position={marker} />}
    </GoogleMap>
  );
};
