import { FC } from 'react';
import { StyledMap } from './styled';

interface SmallMapProps {
  lat: number;
  lng: number;
}

export const SmallMap: FC<SmallMapProps> = ({ lat, lng }) => {
  return <StyledMap zoom={15} center={{ lat, lng }} marker={{ lat, lng }} />;
};
