import { Heading, Space } from 'components/Common';
import { DemoMainLayout } from 'components/Layout/DemoMainLayout';
import { FC, useEffect, useState } from 'react';
import { Ideas } from './Ideas';
import { useWindowSize } from 'hooks/useScreenSize';

export const GiftIdea: FC = () => {
  const [space, setSpace] = useState(650);
  const { width } = useWindowSize();

  useEffect(() => {
    if (width) {
      if (width > 430) {
        setSpace(650);
        return;
      }
      if (width > 370) {
        setSpace(600);
        return;
      }
      if (width > 310) {
        setSpace(490);
        return;
      }
      if (width > 260) {
        setSpace(380);
        return;
      }
    }
  }, [width]);
  return (
    <DemoMainLayout>
      <Space space={space} />
      <Heading variant='h4' text='headers.giftIdea' />
      <Space space={10} />
      <Ideas />
    </DemoMainLayout>
  );
};
