export interface UserEntity {
  id: string;
  login: string;
  password: string;
  accessToken: string;
  refreshToken: string;
  resetToken: string;
  firstName: string;
  lastName: string;
  email: string;
  role: UserRole;
}

export interface UserSettingsEntity {
  url: string;
  theme: UserTheme;
}

export interface UserCreateReq {
  login: string;
  password: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface UserSettingUpdateReq {
  id: string;
  url: string;
  theme: UserTheme;
}

export interface UserSettingsRes {
  id: string;
  url: string;
  theme: UserTheme;
}

export interface UserRes {
  id: string;
  firstName: string;
  lastName: string;
}

export enum UserRole {
  ADMIN,
  CLIENT,
}

export enum UserTheme {
  BASE,
  BLUE,
  GREEN_BOTTLE,
}
