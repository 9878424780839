import { Card, Text } from 'components/Common';
import { styled } from '@mui/material/styles';

export const StyledCard = styled(Card)(
  ({ theme }) => `
  padding: ${theme.spacing(2)};
  border: solid 1px ${theme.palette.secondary.main};
  border-radius: 10px;
  box-shadow: ${theme.shadows[1]}
`
);

export const HistoryText = styled(Text)(
  ({ theme }) => `
  text-align: center;
  `
);
