import { styled } from '@mui/material/styles';
import { GridItemContainer } from '..';
import {
  FloatingActionButton,
  FloatingActionButtonProps,
} from 'components/Common';

export const MainLayoutContainer = styled(GridItemContainer)(
  ({ theme }) => `
  justify-content: center;
  max-width: 427px;
  position: relative;
  overflow: hidden;
  padding-bottom: ${theme.spacing(8)};
  `
);

export const ContentContainer = styled(GridItemContainer)(
  ({ theme }) => `
  padding-top: ${theme.spacing(6)};
  padding-bottom: ${theme.spacing(3)};
  justify-content: center;
  gap: ${theme.spacing(3)};
  position: absolute;
  left: 0;
  top: 0;
  position: relative;
`
);

export const Fab = styled(FloatingActionButton)<FloatingActionButtonProps>(
  ({ theme }) => `
  background-color: ${theme.palette.secondary.main};
  color: ${theme.palette.primary.main};
  position: fixed;
  bottom: ${theme.spacing(3)};
  right: ${theme.spacing(3)};
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.25);
`
);

export const BackgroundWrapper = styled(GridItemContainer)(
  ({ theme }) => `
  position: absolute;
  justify-content: center;
  width: 100%
  `
);
