import { FC } from 'react';
import { StyledCard, DescriptionText, HeaderText } from './styled';

interface IdeasProps {}

export const Ideas: FC<IdeasProps> = () => {
  const tempData = [
    {
      header: 'Bon IKEA',
      description:
        'Goście Drodzy, Goście Mili. Radość byście Nam sprawili w szczęście nasze inwestując kartę podarunkową do Ikei nam darując, oszczędzacie kwiatów moc, co by zwiędły w jedną noc. Ten gest pomoże nam urządzić nasze nowe mieszkanie, spełniając marzenie o wspólnym ciepłym gniazdku.',
    },
    {
      header: 'Zagwostka z pomysłem na prezent?',
      description:
        'Aby ulżyć Wam w udręce co za paczkę dać w prezencie młoda Para podpowiada i zarazem pięknie prosi by w kopertę włożyć grosik.',
    },
  ];

  const renderCard = tempData.map(({ header, description }) => (
    <StyledCard>
      <HeaderText variant='h6'>{header}</HeaderText>
      <DescriptionText variant='body1'>{description}</DescriptionText>
    </StyledCard>
  ));

  return <>{renderCard}</>;
};
