import { MobileNav } from 'components/Nav';
import BG1 from '../../Images/bg1.jpg';
import BG2 from '../../Images/bg2.jpg';
import BG3 from '../../Images/bg3.jpg';
import BG4 from '../../Images/bg4.jpg';
import BG5 from '../../Images/bg5.jpg';
import { FC, ReactNode } from 'react';
import {
  BackgroundWrapper,
  ContentContainer,
  MainLayoutContainer,
} from './styled';
import { useTranslation } from 'react-i18next';
import { Photo, PhotoOverlay } from 'components/Common';

interface MainLayoutProps {
  children?: ReactNode;
}

const tempNav = [
  { text: 'nav.information', to: '/ula&dawid' },
  // { text: 'nav.howItHappened', to: '/ula&dawid/how-it-happened' },
  { text: 'nav.weddingParty', to: '/ula&dawid/party' },
  { text: 'nav.giftIdea', to: '/ula&dawid/gift-idea' },
];

export const DemoMainLayout: FC<MainLayoutProps> = ({ children }) => {
  const { t } = useTranslation();

  return (
    <MainLayoutContainer>
      <MobileNav items={tempNav} demoTitle={t('nav.title')} />
      <BackgroundWrapper>
        <PhotoOverlay mode='bottom' specialAlfa={0.6} showSubtitle>
          <Photo src={BG1} />
        </PhotoOverlay>
        <PhotoOverlay mode='full' specialAlfa={0.6}>
          <Photo src={BG2} />
        </PhotoOverlay>
        <PhotoOverlay mode='top' specialAlfa={0.6} alfa={0.6}>
          <Photo src={BG3} />
        </PhotoOverlay>
        <PhotoOverlay mode='bottom' specialAlfa={0.6} alfa={0.6}>
          <Photo src={BG4} />
        </PhotoOverlay>
        <PhotoOverlay mode='full' specialAlfa={0.6}>
          <Photo src={BG5} />
        </PhotoOverlay>
      </BackgroundWrapper>
      <ContentContainer xs={9}>{children}</ContentContainer>
    </MainLayoutContainer>
  );
};
