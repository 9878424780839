import { CustomRouter, MainRouting } from 'components/Routes';
import { createBrowserHistory } from 'history';
import { themes } from 'config/Theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { AppContainer } from 'components/Layout/AppContainer';
import { Provider } from 'react-redux';
import { store } from 'store';
import NotificationContainer from 'components/Layout/NotificationContainer';

export const history = createBrowserHistory();

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={themes[0]}>
        <NotificationContainer />
        <CustomRouter history={history}>
          <CssBaseline>
            <AppContainer>
              <MainRouting />
            </AppContainer>
          </CssBaseline>
        </CustomRouter>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
