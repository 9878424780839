import { createTheme, Theme, ThemeOptions } from '@mui/material';
import { green, orange, grey, blue, red } from '@mui/material/colors';
import { createContext } from 'react';
import { breakpoints } from '../Breakpoints';

import roseOne from 'components/Images/rose_1.png';
import roseTwo from 'components/Images/rose_2.png';
import roseThree from 'components/Images/rose_3.png';

import blueOne from 'components/Images/blue_1.png';
import blueTwo from 'components/Images/blue_2.png';
import blueThree from 'components/Images/blue_3.png';

import greenOne from 'components/Images/green_1.png';
import greenTwo from 'components/Images/green_2.png';
import greenThree from 'components/Images/green_3.png';

const baseThemeObj: ThemeOptions = {
  breakpoints: breakpoints,
  typography: {
    fontFamily: ['Oswald', 'sans-serif'].join(','),
  },
  shadows: [
    'none',
    '0px 3px 5px rgba(0, 0, 0, 0.25)',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
  ],
  palette: {
    primary: {
      light: blue[300],
      main: '#FFFFFF',
      dark: blue[700],
    },
    secondary: {
      light: grey[300],
      main: '#FFFFFF',
      dark: grey[700],
    },
    success: {
      light: green[300],
      main: green[500],
      dark: green[700],
    },
    error: {
      light: red[300],
      main: red[500],
      dark: red[700],
    },
    warning: {
      light: orange[300],
      main: orange[500],
      dark: orange[700],
    },
    info: {
      light: blue[300],
      main: blue[500],
      dark: blue[700],
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#FFFFFF',
      disabled: blue[700],
    },
  },
  darkModePalette: {
    primary: {
      light: blue[300],
      main: blue[500],
      dark: blue[700],
    },
    secondary: {
      light: grey[300],
      main: grey[500],
      dark: grey[700],
    },
    success: {
      light: green[300],
      main: green[500],
      dark: green[700],
    },
    error: {
      light: red[300],
      main: red[500],
      dark: red[700],
    },
    warning: {
      light: orange[300],
      main: orange[500],
      dark: orange[700],
    },
    info: {
      light: blue[300],
      main: blue[500],
      dark: blue[700],
    },
  },
  modal: {
    backdrop: grey[300],
    container: '#FFF',
    fontColor: '#000',
  },
};

//@ If you wanna write own property
declare module '@mui/material/styles' {
  interface Theme {
    shadows: ['none', string];
    darkModePalette: {
      primary: {
        light: string;
        main: string;
        dark: string;
      };
      secondary: {
        light: string;
        main: string;
        dark: string;
      };
      success: {
        light: string;
        main: string;
        dark: string;
      };
      error: {
        light: string;
        main: string;
        dark: string;
      };
      warning: {
        light: string;
        main: string;
        dark: string;
      };
      info: {
        light: string;
        main: string;
        dark: string;
      };
    };
    modal: {
      backdrop: string;
      container: string;
      fontColor: string;
    };
  }
  interface ThemeOptions {
    darkModePalette: {
      primary: {
        light: string;
        main: string;
        dark: string;
      };
      secondary: {
        light: string;
        main: string;
        dark: string;
      };
      success: {
        light: string;
        main: string;
        dark: string;
      };
      error: {
        light: string;
        main: string;
        dark: string;
      };
      warning: {
        light: string;
        main: string;
        dark: string;
      };
      info: {
        light: string;
        main: string;
        dark: string;
      };
    };
    modal: {
      backdrop: string;
      container: string;
      fontColor: string;
    };
  }
}

//@Themes

const baseTheme = createTheme(baseThemeObj);

const bottleGreenTheme = createTheme({
  ...baseThemeObj,
  palette: {
    primary: {
      light: blue[300],
      main: '#B28F00',
      dark: blue[700],
    },
    secondary: {
      light: grey[300],
      main: '#024234',
      dark: grey[700],
    },
    success: {
      light: green[300],
      main: green[500],
      dark: green[700],
    },
    error: {
      light: red[300],
      main: red[500],
      dark: red[700],
    },
    warning: {
      light: orange[300],
      main: orange[500],
      dark: orange[700],
    },
    info: {
      light: blue[300],
      main: blue[500],
      dark: blue[700],
    },
    text: {
      primary: '#444956',
      secondary: '#D5AFA7',
      disabled: blue[700],
    },
  },
});

const blueTheme = createTheme({
  ...baseThemeObj,
  palette: {
    primary: {
      light: blue[300],
      main: '#B28F00',
      dark: blue[700],
    },
    secondary: {
      light: grey[300],
      main: '#03267B',
      dark: grey[700],
    },
    success: {
      light: green[300],
      main: green[500],
      dark: green[700],
    },
    error: {
      light: red[300],
      main: red[500],
      dark: red[700],
    },
    warning: {
      light: orange[300],
      main: orange[500],
      dark: orange[700],
    },
    info: {
      light: blue[300],
      main: blue[500],
      dark: blue[700],
    },
    text: {
      primary: '#444956',
      secondary: '#D5AFA7',
      disabled: blue[700],
    },
  },
});

//@Contexts

export const ThemeDemoContext = createContext({
  theme: baseTheme,
  index: 0,
  setIndex: (index: number) => {},
  setTheme: (theme: Theme) => {},
});

export const ThemeContext = createContext({
  theme: baseTheme,
  setTheme: (theme: Theme) => {},
});

//@Exports

export enum FlowerAccessor {
  ONE,
  TWO,
  THREE,
}

export const themes = [baseTheme, blueTheme, bottleGreenTheme];

export const flowers = [
  { 0: roseOne, 1: roseTwo, 2: roseThree },
  { 0: blueOne, 1: blueTwo, 2: blueThree },
  { 0: greenOne, 1: greenTwo, 2: greenThree },
];
