import { Map } from 'components/Common/Map';
import { styled } from '@mui/material/styles';

export const StyledMap = styled(Map)(
  ({ theme }) => `
  width: 100%;
  height: 200px;
  border-radius: 10px;
  box-shadow: ${theme.shadows[1]}
`
);
