import { Button, Heading } from 'components/Common';
import { GridContainer, GridItem, GridItemContainer } from 'components/Layout';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

interface NavProps {
  isOpen: boolean;
}

interface NavListProps {
  isOpen: boolean;
}

export const Nav = styled(GridContainer)<NavProps>(
  ({ theme, isOpen }) => `
  margin-left: 0;
  width: 100%;
  border-radius: 0px 0px 10px 10px;
  transition: 0.5s;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  z-index: 100;
  position: fixed;
  max-width: 427px;
  overflow: hidden;
  background-color: ${isOpen ? 'rgba(0,0,0,0.4)' : 'transparent'};
  height: ${isOpen ? '450px' : '50px'};
`
);

export const NavItem = styled(GridItem)(
  () => `
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
`
);

export const NavIconMenu = styled(MenuIcon)(
  ({ theme }) => `
  width: 40px;
  height: 40px;
  cursor: pointer;
  color: ${theme.palette.primary.main}
`
);

export const NavIconClose = styled(CloseIcon)(
  ({ theme }) => `
  width: 40px;
  height: 40px;
  cursor: pointer;
  color: ${theme.palette.primary.main}
`
);

export const NavHeader = styled(Heading)(
  ({ theme }) => `
  text-align: center;
  color: ${theme.palette.primary.main};
  font-family: 'Dancing Script', cursive;
  `
);

export const NavList = styled(GridItemContainer)<NavListProps>(
  ({ isOpen }) => `
  align-items: center;
  flex-direction: column;
  transition: 0.5s;
  opacity: ${isOpen ? 1 : 0};
`
);

export const NavListItem = styled(Button)(
  ({ theme }) => `
  width: 100%;
  padding: ${theme.spacing(1)};
  color: ${theme.palette.primary.main};
  font-weight: 700;
  font-size: ${theme.typography.body1.fontSize};
`
);
