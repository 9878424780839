import { styled } from '@mui/material/styles';
import { GridContainer } from '..';

export const StyledAppContainer = styled(GridContainer)(
  ({ theme }) => `
  display: flex;
  margin-left: 0;
  margin-top: 0;
  gap: ${theme.spacing(2)};
  flex-wrap: nowrap;
  justify-content: center;
  
  ${theme.breakpoints.down('md')}: {
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
  }
`
);
