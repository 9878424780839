import { DemoMainLayout } from 'components/Layout/DemoMainLayout';
import { FC, useEffect, useState } from 'react';
import { useWindowSize } from 'hooks/useScreenSize';
import { SmallMap } from './Map';
import { Heading, Space } from 'components/Common';
import { WeddingInfo } from './WeddingInfo';
import { PartyInfo } from './PartyInfo';

export const Info: FC = () => {
  const [space, setSpace] = useState(650);
  const { width } = useWindowSize();

  useEffect(() => {
    if (width) {
      if (width > 430) {
        setSpace(650);
        return;
      }
      if (width > 370) {
        setSpace(600);
        return;
      }
      if (width > 310) {
        setSpace(490);
        return;
      }
      if (width > 260) {
        setSpace(380);
        return;
      }
    }
  }, [width]);

  return (
    <DemoMainLayout>
      <Space space={space} />
      <Heading variant='h4' text='headers.wedding' />
      <WeddingInfo />
      <SmallMap lat={49.97038140981343} lng={18.941705107510447} />
      <Space space={80} />
      <Heading variant='h4' text='headers.weddingParty' />
      <PartyInfo />
      <SmallMap lat={49.715438173424104} lng={19.02292033988322} />
    </DemoMainLayout>
  );
};
