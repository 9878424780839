import { FC, ReactNode } from "react";

interface ListProps {
  children?: ReactNode;
  className?: string;
}

export const List: FC<ListProps> = ({ children, className }) => {
  return <ul className={className}>{children}</ul>;
};

export * from './Item';
