import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Info } from 'views/Demo/Info';
import { HowItHappened } from 'views/Demo/HowItHappened';
import { Party } from 'views/Demo/Party';
import { GiftIdea } from 'views/Demo/GiftIdea';
import { GuestBook } from 'views/Demo/GuestBook';
import { Photo } from 'views/Demo/Photo';

export const DemoRouting: FC = () => {
  return (
    <Routes>
      <Route path='/' element={<Info />} />
      {/* <Route path='/how-it-happened' element={<HowItHappened />} /> */}
      <Route path='/party' element={<Party />} />
      <Route path='/gift-idea/*' element={<GiftIdea />} />
      {/* <Route path="/guest-book/*" element={<GuestBook />} />
    <Route path="/photo/*" element={<Photo />} /> */}
      <Route path='/*' element={<Navigate to='/' replace />} />
    </Routes>
  );
};
