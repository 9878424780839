import { FC, useState } from 'react';
import { history } from 'App';
import {
  Nav,
  NavHeader,
  NavIconClose,
  NavIconMenu,
  NavItem,
  NavList,
  NavListItem,
} from './styled';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

export interface NavItems {
  text: string;
  to: string;
}

interface MobileNavProps {
  items: NavItems[];
  demoTitle?: string;
}

export const MobileNav: FC<MobileNavProps> = ({ items, demoTitle }) => {
  const [isOpenNav, setIsOpenNav] = useState<boolean>(false);
  const { title } = useSelector(
    (state: RootState) => state.information.information
  );

  const handleOnClickIcon = () => setIsOpenNav((prev) => !prev);

  const handleOnNav = (path: string) => {
    history.push(path);
  };

  const renderListItems = items.map(({ text, to }) => (
    <NavListItem key={text} text={text} action={() => handleOnNav(to)} />
  ));

  return (
    <Nav isOpen={isOpenNav}>
      <NavItem xs={10}>
        <NavHeader variant='h4'>{demoTitle || title}</NavHeader>
      </NavItem>
      <NavItem xs={2}>
        {isOpenNav ? (
          <NavIconClose onClick={handleOnClickIcon} />
        ) : (
          <NavIconMenu onClick={handleOnClickIcon} />
        )}
      </NavItem>
      <NavList isOpen={isOpenNav}>{renderListItems}</NavList>
    </Nav>
  );
};
