import { Typography } from "@mui/material";
import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";

type VariantType = 
| "h1"
| "h2"
| "h3"
| "h4"
| "h5"
| "h6"
| "subtitle1"
| "subtitle2"
| "body1"
| "body2"
| "caption"
| "button"
| "overline"
| "inherit";

interface TextProps {
  variant: VariantType;
  text?: string;
  children?: ReactNode;
  className?: string
}

export const Text: FC<TextProps> = ({ variant, text, children, className }) => {
  const { t } = useTranslation()
  return (
    <Typography variant={variant} className={className}>
      {text ? t(text) : children}
    </Typography>
  );
};