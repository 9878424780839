import { FC } from 'react';
import { StyledCard, DescriptionText, HeaderText } from './styled';

interface OrganizationProps {}

export const Organization: FC<OrganizationProps> = () => {
  const tempData = [
    {
      header: 'Życzenia',
      description: 'Składanie życzeń przewidujemy na sali weselnej.',
    },
    {
      header: 'Dojazd',
      description: 'Polecamy wybranie trasy S1 w kierunku Żywca.',
    },
    {
      header: 'Parking',
      description:
        'Parking znajduje się za Salą Weselną. Należy skręcić w prawo w pierwszą drogę za salą weselną, a następnie jechać ok. 200m i ponownie skręcić w prawo przy banerze informującym o parkingu dla Gości.',
    },
  ];

  const renderCard = tempData.map(({ header, description }) => (
    <StyledCard>
      <HeaderText variant='h6'>{header}</HeaderText>
      <DescriptionText variant='body1'>{description}</DescriptionText>
    </StyledCard>
  ));

  return <>{renderCard}</>;
};
