import { FC } from 'react';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

interface NotificationContainerProps {
  position?: 'top-right' | 'top-center' | 'top-left' | 'bottom-right' | 'bottom-center' | 'bottom-left';
}
 
const NotificationContainer: FC<NotificationContainerProps> = ({ position }) => {
  return <ToastContainer
  position={position || 'top-right'}
  autoClose={5000}
  newestOnTop
  closeOnClick
  pauseOnHover
/>;
}
 
export default NotificationContainer;