import { ThemeDemoContext } from 'config/Theme';
import { ThemeProvider } from '@mui/material';
import { FC, ReactNode, useContext } from 'react';

interface Props {
  children: ReactNode;
}
 
export const ThemeWrapper: FC<Props> = ({ children }) => {
  const {theme} = useContext(ThemeDemoContext)
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}