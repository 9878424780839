import { FC, useState } from 'react';
import { DemoRouting } from 'components/Routes/Routing';
import { themes, ThemeDemoContext } from 'config/Theme';
import { ThemeWrapper } from './ThemeWrapper';

export const Demo: FC = () => {
  const [demoTheme, setDemoTheme] = useState(themes[0]);
  const [index, setIndex] = useState(0);
  return (
    <ThemeDemoContext.Provider
      value={{ theme: demoTheme, setTheme: setDemoTheme, index, setIndex }}
    >
      <ThemeWrapper>
        <DemoRouting />
      </ThemeWrapper>
    </ThemeDemoContext.Provider>
  );
};
