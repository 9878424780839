import { styled } from '@mui/material/styles';

interface SpecialOverlayProps {
  alfa?: number;
  mode: 'full' | 'bottom' | 'top';
}

interface OverlayProps {
  alfa?: number;
}

export const PhotoOverlayWrapper = styled('div')(
  ({ theme }) => `
  display: flex;
  width: 100%;
  position: relative;
`
);

export const Overlay = styled('div')<OverlayProps>(
  ({ alfa = 0.3 }) => `
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,${alfa});
`
);

export const SpecialOverlay = styled('div')<SpecialOverlayProps>(
  ({ alfa = 0.3, mode }) => `
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,${alfa});
  ${
    mode === 'full' &&
    `
    background-color: rgba(0,0,0,${alfa});
  `
  }

  ${
    mode === 'bottom' &&
    `
    background-color: rgba(0,0,0,${alfa});
    clip-path: polygon(100% 65%, 0% 100%, 100% 101%);
  `
  }

  ${
    mode === 'top' &&
    `
  background-color: rgba(0,0,0,${alfa});
  clip-path: polygon(100% 0, 0 0, 0 35%);`
  }

`
);
