import { FC } from 'react';
import { PositionedHeader } from './styled';
import { getWeddingCounterValue } from 'utils/date';

interface WeddingCounterProps {}

export const WeddingCounter: FC<WeddingCounterProps> = () => {
  const toWedding = getWeddingCounterValue(new Date('2024-08-08T13:00:00'));
  return (
    <PositionedHeader variant='h3'>
      {toWedding ? `Za ${toWedding}` : 'Teraz'}
    </PositionedHeader>
  );
};
