import { Paper } from '@mui/material';
import { FC, ReactNode } from 'react';

interface CardProps {
  children?: ReactNode;
  className?: string;
}
 
export const Card: FC<CardProps> = ({children, className}) => {
  return <Paper className={className}>{children}</Paper>;
}
 