import { Grid } from "@mui/material";
import { FC, ReactNode } from "react";

interface GridItemProps {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  children?: ReactNode;
  className?: string;
}

export const GridItem: FC<GridItemProps> = ({
  xs,
  sm,
  md,
  lg,
  xl,
  children,
  className,
}) => {
  return (
    <Grid className={className} item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      {children}
    </Grid>
  );
};
