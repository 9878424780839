import { Card, List, ListItem } from 'components/Common';
import { styled } from '@mui/material/styles';

export const StyledCard = styled(Card)(
  ({ theme }) => `
  width: 100%;
  padding: ${theme.spacing(2)};
  border: solid 1px ${theme.palette.secondary.main};
  border-radius: 10px;
  box-shadow: ${theme.shadows[1]}
`
);

export const MenuList = styled(List)(
  ({ theme }) => `
  `
);

export const MenuListItem = styled(ListItem)(
  ({ theme }) => `
  `
);
