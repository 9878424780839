import { FC, ReactNode } from 'react';
import { StyledAppContainer } from './styled';

interface AppContainerProps {
  children: ReactNode;
}

export const AppContainer: FC<AppContainerProps> = ({ children }) => {
  return <StyledAppContainer>{children}</StyledAppContainer>;
};
