import { Typography } from "@mui/material";
import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";

type HeadingVariant = "h1" | "h2" | "h3" | "h4" | "h5" | "h6"
interface HeadingProps {
  variant: HeadingVariant;
  text?: string;
  children?: ReactNode;
  className?: string;
}

export const Heading: FC<HeadingProps> = ({
  variant,
  text,
  children,
  className,
}) => {
  const { t } = useTranslation()
  return (
    <Typography variant={variant} className={className}>
      {text ? t(text) : children}
    </Typography>
  );
};
