import { styled } from '@mui/material/styles';
import { Heading } from 'components/Common';

export const PositionedHeader = styled(Heading)(
  ({ theme }) => `
  position: absolute;
  left: 40%;
  bottom: 5%;
  width: 240px;
  text-align: center;

  @media (max-width: 430px) {
    font-size: ${theme.spacing(5)};
    width: 200px;
  }

  @media (max-width: 370px) {
    font-size: ${theme.spacing(4)};
    width: 180px;
  }

  @media (max-width: 310px) {
    font-size: ${theme.spacing(3)};
    width: 160px;
  }

  @media (max-width: 260px) {
    font-size: ${theme.spacing(2)};
    width: 150px;
  }
`
);
