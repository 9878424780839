import { FC } from 'react';
import { SpecialSpace } from './styled';

interface SpaceProps {
  space: number;
}

export const Space: FC<SpaceProps> = ({ space }) => {
  return <SpecialSpace space={space} />;
};
