import { GridItem, GridItemContainer } from 'components/Layout';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface LineProps {
  lineColor?: string;
}

export const Container = styled(GridItemContainer)(
  ({ theme }) => `
  align-items: center;
  justify-content: space-between;
`
);

export const LineContainer = styled('div')(
  ({ theme }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`
);

export const Line = styled(GridItem)<LineProps>(
  ({ theme, lineColor }) => `
  height: 1px;
  width: 90%;
  background-color: ${lineColor ?? theme.palette.secondary.main};
`
);

export const TextContainer = styled(GridItem)<LineProps>(
  ({ theme }) => `
  align-items: center;
  justify-content: center;
`
);

export const Text = styled(Typography)<LineProps>(
  ({ theme }) => `
  color: ${theme.palette.text.primary};
  `
);
