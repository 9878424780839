import { styled } from '@mui/material/styles';

interface SpecialSpaceProps {
  space: number;
}

export const SpecialSpace = styled('div')<SpecialSpaceProps>(
  ({ space }) => `
  width: 100%;
  height: ${space}px;
  `
);
