import { appDateFormat, appTimeFormat } from 'config/default';
import {
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  format,
  formatDistanceToNowStrict,
  isAfter,
} from 'date-fns';
import { pl } from 'date-fns/locale';

export const getUnitToCounter = (date: Date) => {
  if (differenceInSeconds(date, new Date()) < 60) return 'second';
  if (differenceInMinutes(date, new Date()) < 60) return 'minute';
  if (differenceInHours(date, new Date()) < 24) return 'hour';
  return 'day';
};

export const getWeddingCounterValue = (date: Date) => {
  if (!isAfter(date, new Date())) return 0;
  return formatDistanceToNowStrict(new Date(date), {
    locale: pl,
    unit: getUnitToCounter(new Date(date)),
  });
};

export const getDataFormat = (date: Date) => format(date, appDateFormat);

export const getTimeFormat = (date: Date) => format(date, appTimeFormat);
