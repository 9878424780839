import { AuthorizedRoute } from 'components/Routes/AuthorizedRoute';
import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Demo } from 'views/Demo';

export const MainRouting: FC = () => (
  <Routes>
    <Route path='/' element={<Navigate to='/ula&dawid' replace />} />
    <Route path='/ula&dawid/*' element={<AuthorizedRoute />}>
      <Route path='*' element={<Demo />} />
    </Route>
    {/* <Route path="/page/:coupleUrl/*" element={<AuthorizedRoute />}>
      <Route path="*" element={<Page />} />
    </Route> */}
    <Route path='*' element={<Navigate to='/' replace />} />
  </Routes>
);
