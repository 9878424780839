import {ReactComponent as Calendar} from 'components/Images/CalendarIcon.svg'
import {ReactComponent as Clock} from 'components/Images/ClockIcon.svg'
import {ReactComponent as Location} from 'components/Images/LocationIcon.svg'
import {ReactComponent as Rings} from 'components/Images/RingsIcon.svg'
import { SvgIcon, SvgIconProps } from "@mui/material";

export const CalendarIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <Calendar />
  </SvgIcon>
);

export const ClockIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <Clock />
  </SvgIcon>
);

export const LocationIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <Location />
  </SvgIcon>
);

export const RingsIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <Rings />
  </SvgIcon>
);
