import { TextWithIcon } from 'components/Common/TextWithIcon';
import { LocationIcon } from 'components/Icons';
import { GridContainer, GridItem } from 'components/Layout';
import { FC } from 'react';
import { CenteredText } from './styled';

interface PartyInfoProps {}

export const PartyInfo: FC<PartyInfoProps> = () => {
  return (
    <GridContainer rowSpacing={6}>
      <GridItem xs={12}>
        <CenteredText variant='body1'>
          Po ślubie zapraszamy na przyjęcie weselne. Przewidujemy wspaniałą
          zabawe do białego rana! :)
        </CenteredText>
      </GridItem>
      <GridItem xs={12}>
        <TextWithIcon
          variant='body1'
          leftIcon={<LocationIcon color='secondary' />}
        >
          Sala Weselna Venecia Górska 2, Szczyrk
        </TextWithIcon>
      </GridItem>
    </GridContainer>
  );
};
