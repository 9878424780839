import { styled } from '@mui/material/styles';
import { GridItemContainer } from '..';

export const MainLayoutContainer = styled(GridItemContainer)(
  ({ theme }) => `
  padding-top: ${theme.spacing(3)};
  justify-content: center;
  max-width: 600px;
`
);

export const ContentContainer = styled(GridItemContainer)(
  ({ theme }) => `
  padding-top: ${theme.spacing(6)};
  padding-bottom: ${theme.spacing(3)};
  justify-content: center;
  gap: ${theme.spacing(3)};
`
);
