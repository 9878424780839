import { Fab } from '@mui/material';
import { FC, ReactNode } from 'react';

export interface FloatingActionButtonProps {
  icon?: ReactNode;
  onClick?: () => void;
  className?: string;
}
 
export const FloatingActionButton: FC<FloatingActionButtonProps> = ({ icon, onClick, className }) => {
  return <Fab onClick={onClick} className={className}>{icon}</Fab>;
}