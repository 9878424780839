import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IconContainer, TextContainer, Text, Container } from "./styled";

type VariantType = 
| "h1"
| "h2"
| "h3"
| "h4"
| "h5"
| "h6"
| "subtitle1"
| "subtitle2"
| "body1"
| "body2"
| "caption"
| "button"
| "overline"
| "inherit";

interface TextWithIconProps {
  variant: VariantType;
  text?: string;
  children?: string;
  className?: string;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
}

export const TextWithIcon: FC<TextWithIconProps> = ({
  text,
  children,
  className,
  variant,
  leftIcon,
  rightIcon,
  ...rest
}) => {
  const { t } = useTranslation();
  return (
    <Container {...rest}>
      {leftIcon && (
        <IconContainer>
          {leftIcon}
        </IconContainer>
      )}
      <TextContainer>
        <Text variant={variant} className={className}>
          {text ? t(text) : children}
        </Text>
      </TextContainer>
      {rightIcon && (
        <IconContainer>
          {rightIcon}
        </IconContainer>
      )}
    </Container>
  );
};
