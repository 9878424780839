import { FC } from "react";
import { Outlet, Navigate } from "react-router-dom";
// import { getLocalStorageItem } from "utils/localStorage";

export const AuthorizedRoute: FC = () => {
  // const token = getLocalStorageItem('accessToken');

  // if (!token) {
  //   return <Navigate to="/login" replace/>;
  // }

  return <Outlet />;
};
