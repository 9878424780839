import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface ListItemProps {
  text?: string;
  children?: ReactNode;
  className?: string;
}

export const ListItem: FC<ListItemProps> = ({ text, children, className }) => {
  const { t } = useTranslation();
  return <li className={className}>{text ? t(text) : children}</li>;
};
