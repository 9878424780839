import { FC, ReactNode } from 'react';
import {Button as MuiButton} from '@mui/material'
import { useTranslation } from 'react-i18next';

interface ButtonProps {
  text?: string;
  action: () => void;
  children?: ReactNode;
  className?: string;
}
 
export const Button: FC<ButtonProps> = ({text, action, children, className}) => {
  const { t } = useTranslation();
  return <MuiButton onClick={action} className={className}>{text ? t(text) : children}</MuiButton>;
}