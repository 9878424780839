import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InformationPageRes, UserTheme } from 'wedding-back/types';

interface InitialState {
  information: InformationPageRes;
}

const initialState: InitialState = {
  information: {
    title: '',
    weddingDate: new Date(),
    weddingLocation: {
      name: '',
      coordinates: {
        lat: 50,
        lng: 50,
      },
    },
    partyInvitation: '',
    partyLocation: {
      name: '',
      coordinates: {
        lat: 50,
        lng: 50,
      },
    },
    loveStory: '',
    weddingPlan: [],

    weddingMenu: [],
    organizationThings: [],
    presentIdea: [],
    theme: UserTheme.BASE,
  },
};

export const informationSlice = createSlice({
  name: 'information',
  initialState,
  reducers: {
    setPageInformation: (state, action: PayloadAction<InformationPageRes>) => {
      state.information = action.payload;
    },
  },
});

export const { setPageInformation } = informationSlice.actions;

export default informationSlice.reducer;
