import { Card, Heading, Text } from 'components/Common';
import { styled } from '@mui/material/styles';

export const StyledCard = styled(Card)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${theme.spacing(2)};
  padding: ${theme.spacing(2)};
  border: solid 1px ${theme.palette.secondary.main};
  border-radius: 10px;
  box-shadow: ${theme.shadows[1]};
  width: 100%;
`
);

export const DescriptionText = styled(Text)(
  ({ theme }) => `
  text-align: center;
  `
);

export const HeaderText = styled(Heading)(
  ({ theme }) => `
  text-align: center;
  `
);
