import { Grid } from "@mui/material";
import { FC, ReactNode } from "react";

interface GridContainerProps {
  spacing?: number;
  rowSpacing?: number;
  columnSpacing?: number;
  children?: ReactNode;
  className?: string;
}

export const GridContainer: FC<GridContainerProps> = ({
  spacing = 0,
  rowSpacing = 0,
  columnSpacing = 0,
  children,
  className,
}) => {
  return (
    <Grid
      className={className}
      container
      spacing={spacing}
      rowSpacing={rowSpacing}
      columnSpacing={columnSpacing}
    >
      {children}
    </Grid>
  );
};
