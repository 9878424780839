import { FC, ReactNode } from 'react';
import { Overlay, PhotoOverlayWrapper, SpecialOverlay } from './styled';
import { WeddingCounter } from './Counter';

interface PhotoOverlayProps {
  mode?: 'full' | 'bottom' | 'top';
  alfa?: number;
  specialAlfa?: number;
  showSubtitle?: boolean;
  children: ReactNode;
}

export const PhotoOverlay: FC<PhotoOverlayProps> = ({
  alfa,
  specialAlfa,
  showSubtitle,
  mode,
  children,
}) => {
  return (
    <PhotoOverlayWrapper>
      {children}
      <Overlay alfa={alfa} />
      {mode && <SpecialOverlay mode={mode} alfa={specialAlfa} />}
      {showSubtitle && <WeddingCounter />}
    </PhotoOverlayWrapper>
  );
};
