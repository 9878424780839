import { Box } from "@mui/material";
import { FC } from "react";

interface PhotoProps {
  src: string;
  alt?: string;
}

export const Photo: FC<PhotoProps> = ({ src, alt }) => {
  return (
    <Box>
      <img
        src={src}
        alt={alt}
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
      />
    </Box>
  );
};
