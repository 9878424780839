import * as React from 'react';
import { Router } from 'react-router-dom';

export const CustomRouter = ({ ...props }) => {
  const { history } = props;
  const [state, setState] = React.useState({
    action: history.action,
    location: history.location
  });

  React.useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router
      {...props}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
};